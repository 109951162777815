import React, { useEffect, useState } from "react";
import "./Home.css";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { expertGet } from "../../Redux/action/auth";
import { cloudURL } from "../../Util/helper";
import Loader from "../../Util/Loader";
import Vimeo from "@u-wave/react-vimeo";
const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading, expertGetData } = useSelector(
    (state) => state.getHomeExpert
  );
  const [isIframe, setIsIframe] = useState(false);

  useEffect(() => {
    dispatch(expertGet());
  }, []);

  const CustomPrevArrow = ({ className, style, onClick }) => {
    return (
      <div
        className={`${className} leading_experts_slick_prev`}
        style={{ ...style }}
        onClick={onClick}
      />
    );
  };

  const CustomNextArrow = ({ className, style, onClick }) => {
    return (
      <div
        className={`${className} leading_experts_slick_next`}
        style={{ ...style }}
        onClick={onClick}
      />
    );
  };

  const toggleDisplay = () => {
    setIsIframe(true);
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrow: true,
          prevArrow: <CustomPrevArrow />,
          nextArrow: <CustomNextArrow />,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrow: true,
          prevArrow: <CustomPrevArrow />,
          nextArrow: <CustomNextArrow />,
        },
      },
    ],
  };

  return (
    <div className="home_main">
      <div className="banner_main">
        <Container fluid style={{ paddingLeft: "0" }}>
          <Row>
            <Col md={6}>
              <div className="banner_left">
                <h2>SIGN UP NOW</h2>
                <h1>This course is 100% online 100% free for students</h1>
                <p>
                  This course will allow students to analyze the ideology of
                  what design disruption is, looking at the historic
                  connotations of the concept of ‘street wear’ as well as its
                  current iterations; between authenticity and commodity, luxury
                  and accessibility, clothes and fashion, form and function.{" "}
                </p>

                <div className="text-center mt-4">
                  <button onClick={() => navigate("/signup")}>SIGN UP</button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section1_banner">
        <Container>
          <Row className="section1-row">
            <Col md={12}>
              <img src="/images/mainLogo.svg" alt="" className="top_logo" />
            </Col>

            <Col md={5}>
              <div className="section1_left">
                {/* <h1>GET A SNEAK PEEK</h1> */}
                <div>
                  <img src="/images/hand-drawn-titles/GetASbk.svg" />
                </div>

                <h2>
                  Learn from the <br />
                  leading disrupters
                </h2>
              </div>
            </Col>
            <Col md={7} className="vimeo-container">
              {/* <div
                style={{ display: `${isIframe ? "none" : "flex"}` }}
                onClick={toggleDisplay}
              >
                <button>Play</button>
              </div> */}
              <Vimeo
                video={`https://vimeo.com/882157649?share=copy`}
                autoplay
                // style={{ display: `${isIframe ? "flex" : "none"}` }}
              />
            </Col>

            <Col md={12}>
              <button onClick={() => navigate("/signup")}>Sign up</button>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="leading_experts">
        <Container className="mb-5">
          {/* <h1>industry experts</h1> */}
          <div className="IndExpbk">
            <img src="/images/hand-drawn-titles/IndExpbk.svg" />
          </div>
          <img src="/images/leading_expert_underline.png" alt="" />
          {loading ? (
            <div style={{ margin: "70px 0" }}>
              {" "}
              <Loader />{" "}
            </div>
          ) : (
            <Slider {...settings} className="mt-5">
              {expertGetData?.data?.rows?.map((e) => {
                return (
                  <div className="expert_box">
                    <img
                      src={`${cloudURL}logo/${e?.user_avatar}`}
                      alt=""
                      style={{ height: "234px", objectFit: "cover" }}
                    />
                    <h5>{`${e.first_name} ${e.last_name}`}</h5>
                    <p>{e?.Profession?.title}</p>
                  </div>
                );
              })}
            </Slider>
          )}
        </Container>
        <Container className="mt-5" id="about">
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="title_cactus_box">
                <img src="/images/title_cactus.png" alt="" />
                <p>
                  Cactus Jack Gardens is a community garden initiative that has
                  partnered with several elementary schools across Houston, TX
                  where students learn agricultural economics and nutritional
                  skills to combat food insecurity. The initiative launched on
                  November 4th, 2021 at Young Elementary School with a permanent
                  reading pergola dedicated to Scott’s grandmother, a Houstonian
                  native, called Miss Sealie’s Corner. The foundation continues
                  to roll out new gardens throughout the city through their
                  multi-school expansion program, with the latest garden
                  unveiling on Earth Day 2022 at Attucks Middle School, hosted
                  by Scott’s mother Wanda Webster and Cactus Jack Foundation
                  volunteers.
                </p>

                <div className="text-center mt-5">
                  <button onClick={() => navigate("/signup")}>Sign Up</button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="program_overview">
        <Container className="mb-5">
          <div className="ProgramObk">
            <img src="/images/hand-drawn-titles/ProgramObk.svg" />
          </div>
          <img src="/images/leading_expert_underline.png" alt="" />
        </Container>

        <div className="program_overview_section">
          <Container>
            <Row className="justify-content-around">
              <Col md={3}>
                <div className="program_box">
                  <h5>Learn the Business</h5>

                  <p>
                    Students will gain an understanding and apply the theory of
                    “design disruption” in the fashion / music industry.
                  </p>
                </div>
              </Col>

              <Col md={3}>
                <div className="program_box">
                  <h5>
                    Learn from the <br /> experts
                  </h5>

                  <p>
                    These experts work closely with Travis and at the Cactus
                    Jack brand to break down what is normal and be the best in
                    the industry.
                  </p>
                </div>
              </Col>

              <Col md={3}>
                <div className="program_box">
                  <h5>Experiential Learning</h5>

                  <p>
                    The program's emphasis on experiential learning immerses
                    students in the subject matter, allowing for a deeper grasp
                    of concepts.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <section className={"fashion_section"}>
        <Container>
          <div className={"fashion_wrapper"}>
            <img src="/images/fashion.svg" alt="" />
            <p>
              The Cactus Jack Design Ethos program, conceived by the Cactus Jack
              Foundation and the visionary artist Travis Scott, redefines
              creative education. This asynchronous program combines
              industry-leading insights with hands-on projects, offering
              students a unique journey through the realms of fashion, design,
              and the music industry.
            </p>

            <p>
              This program immerses students in the world that shaped Travis
              Scott’s artistic journey and the iconic Cactus Jack brand. It
              encourages students to critically examine the concept of ‘design
              disruption’ and fosters independent thinking.
            </p>

            <p>
              One of the program’s standout features is the opportunity to learn
              from insiders—key players within the Cactus Jack crew and industry
              experts. Their insights provide a deeper understanding of fashion,
              music, and design, breaking down preconceptions and offering a
              comprehensive view of these dynamic fields.
            </p>
            <p>
              Fashion Scholarship Fund Scholars, Alumni, and eligible students
              who have entered the FSF’s application portal, submit their final
              project after completing all the videos and assignments, to
              compete for a cash award of $10,000. Two winners will be selected,
              and, in addition to receiving $10,000, they will also have the
              opportunity to visit the Cactus Jack Foundation’s design and
              innovation hub in Houston, Texas for an immersive experience. Sign
              up today for free and start your journey.
            </p>
            <div className="text-center mt-4">
              <button onClick={() => navigate("/signup")}>Sign Up</button>
            </div>
          </div>
        </Container>
      </section>

      <section className="bottom_image_wrapper">
        <img src="/images/bottom-img.jpg" alt="bottom-img" />
      </section>
    </div>
  );
};
export default Home;
